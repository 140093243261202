<template>
  <el-container class="layout-container flex-center layout-backtop">
    <Headers />

    <mb-menu></mb-menu>

    <Mains />

    <el-backtop
      v-if="isNotCase"
      target=".layout-backtop .el-main .el-scrollbar__wrap"
      :key="new Date().getTime()"
    ></el-backtop>
  </el-container>
</template>

<script>
import Headers from './header/header.vue'
import Mains from './main/main.vue'
import MbMenu from '@/components/mbMenu/Mbmenu.vue'

export default {
  name: 'HomeView',
  components: { Headers, Mains, MbMenu },
  data() {
    return {
      isNotCase: false,
      screenWidth: null
    }
  },
  watch: {
    $route: {
      handler(n, o) {
        if (
          n.name != 'userStories' &&
          n.name != 'login' &&
          n.name != 'register'
        ) {
          this.isNotCase = true
        } else {
          this.isNotCase = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth

    window.addEventListener('resize', () => {
      this.screenWidth = document.body.clientWidth
    })
    // window.onresize = () => {
    //   //屏幕尺寸变化
    //   return (() => {})()
    // }
  },
  computed: {
    showCollapse() {
      return this.$store.state.menuVisible
    }
  },
  methods: {}
}
</script>
<style scoped lang="scss">
.layout-container {
}

/* 页面宽度大于769px
------------------------------- */
@media screen and (min-width: 769px) {
  .layout-container {
    .menu {
      display: none;
    }
  }
}
/* 页面宽度小于340px
------------------------------- */
@media screen and (max-width: 340px) {
  .programmes {
    width: 50% !important;
  }
}
/* 页面宽度小于240px
------------------------------- */
@media screen and (max-width: 240px) {
  .programmes {
    width: 100% !important;
  }
}
</style>
 <style>
.menu-collapse .el-collapse-item__content {
  padding-bottom: 0px;
}
</style>