import axios from 'axios'
import Qs from 'qs'
import context from '@/main.js'
import { Toast } from "vant"

// 每次请求携带cookies信息
axios.defaults.withCredentials = true

// 创建axios实例
const request = axios.create({
  baseURL: window._cfg.API_BASE, // api的base_url
  timeout: 20000 // 请求超时时间
})

request.interceptors.request.use(config => {
  if (config.data) {
    var ct = config.headers['Content-Type'];
    var isJsonType = ct && ct.indexOf('json') > -1;
    config.data = isJsonType ? JSON.stringify(config.data) : Qs.stringify(config.data);
  }
  return config
}, error => {
  Promise.reject(error)
})
// respone拦截器
request.interceptors.response.use(
  response => {
    // console.log(context)
    const res = response.data
    if (res.success) return res;
    if (res.code == 'CMP-NL') {
      context.logout();
      context.$router.push({ name: 'login' });
      return res;
    }
    if (response.config.autoTip == false) return res;
    if (document.body.clientWidth > 572) {
      context.$message({ message: res.msg, type: 'error', dangerouslyUseHTMLString: true, });
    } else {
      Toast({ message: res.msg, type: 'fail' });
    }

    return res;
  },
  error => {
    if (error.message === 'Network Error') {
      if (document.body.clientWidth > 572) {
        context.$message({ message: '网络异常，请稍后重试', type: 'error' });
      } else {
        Toast({ message: '网络异常，请稍后重试', type: 'fail' });
      }
    } else {
      if (document.body.clientWidth > 572) {
        context.$message({ message: error.config.url + ':' + error.message, type: 'error' });
      } else {
        Toast({ message: error.config.url + ':' + error.message, type: 'fail' });
      }
    }
    return Promise.reject(error)
  }
)
export default request
