import Vue from 'vue';


import { Toast, Lazyload } from 'vant';

Vue.use(Toast);


const errorImage = require('./loading.png')
const loadingImage = require('./loading.png')

var userAgent = window.navigator.userAgent;
if (userAgent.indexOf('NET') != -1) {
  // //ie浏览器不兼容懒加载组件处理
  Vue.directive('lazy', function (el, binding) {
    el.src = binding.value
  })
} else {
  //实现图片懒加载
  Vue.use(Lazyload, {
    preLoad: 1.5,
    error: errorImage,
    loading: loadingImage
  });
}

import 'vant/lib/index.css'
