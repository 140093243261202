import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuVisible: false,
    infoDialogVisible: false,
    firstClickProgramme: '',
    showRegisterBtn: false,
    menuList: [
      {
        menuItem: '首页',
        path: 'home'
      },
      {
        menuItem: '产品介绍',
        children: [
          {
            sortTitle: '绩效管理',
            selectItem: [
              {
                title: '绩效管理',
                link: '/alert',
                pathName: 'performanceManagement',
                icon: 'performance-management'
              },
              {
                title: '360考核',
                link: '/alert',
                pathName: '360Assessment',
                icon: '360-assessment'
              }
            ]
          },
          {
            sortTitle: '人才评价',
            selectItem: [
              {
                title: '职称评审',
                link: '/alert',
                pathName: 'titleReview',
                icon: 'title-review'
              },
              {
                title: '同行评议',
                link: '/alert',
                pathName: 'peersComment',
                icon: 'peers-comment'
              },
              {
                title: '在线考试',
                link: '/alert',
                pathName: 'onlineExam',
                icon: 'online-exam'
              }
            ]
          },
          {
            sortTitle: '干部管理',
            selectItem: [
              {
                title: '干部信息',
                link: '/alert',
                pathName: 'cadreInformation',
                icon: 'cadre-information'
              },
              {
                title: '选拔任用',
                link: '/alert',
                pathName: 'cadreSelection',
                icon: 'cadre-selection'
              },
              {
                title: '民主测评',
                link: '/alert',
                pathName: 'democraticEvaluation',
                icon: 'democratic-evaluation'
              },
              {
                title: '民主推荐',
                link: '/alert',
                pathName: 'democraticRecommend',
                icon: 'democratic-recommend'
              },
              {
                title: '领导力评估',
                link: '/alert',
                pathName: 'leadershipAssessment',
                icon: 'leadership-assessment'
              }
            ]
          }
        ],
        path: 'product'
      },
      {
        menuItem: '解决方案',
        path: 'productList'
      },
      {
        menuItem: '合作伙伴',
        path: 'cooperativePartner'
      },
      {
        menuItem: '典型用户',
        path: 'typicalUsers'
      },
      {
        menuItem: '关于我们',
        path: 'aboutUs'
      }
    ]
  },
  getters: {
  },
  mutations: {
    "showMenu": (state, menuVisible) => {
      // console.log(menuVisible)
      state.menuVisible = menuVisible
    },
    "showInfoVisible": (state, infoDialogVisible) => {
      // console.log(menuVisible)
      state.infoDialogVisible = infoDialogVisible
    }
  },
  actions: {
  },
  modules: {
  }
})
