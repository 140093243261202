<template>
  <!-- 手机版菜单 -->
  <div class="menu" v-if="showCollapse" @click="handleClick">
    <el-collapse v-model="activeName" accordion class="menu-collapse">
      <el-collapse-item
        :title="item.menuItem"
        :name="index - 1"
        v-for="(item, index) in menuList"
        :key="index"
        :disabled="!item.children"
        @click.native="handleRouterJump(item.path)"
      >
        <div class="solve-programme-menu">
          <div
            v-for="el in item.children"
            :key="el.title"
            class="hj-workbench-item mb16"
            :index="item.index"
          >
            <div class="item-head mb12">
              {{ el.sortTitle }}
            </div>
            <div class="item-content">
              <span
                v-for="sort in el.selectItem"
                :key="sort.sortTitle"
                class="programmes"
                @click="handleRouterJump(sort.pathName, 'son')"
              >
                {{ sort.title }}
              </span>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: -2
    }
  },
  computed: {
    showCollapse() {
      return this.$store.state.menuVisible
    },
    menuList() {
      return this.$store.state.menuList
    }
  },
  methods: {
    handleClick(event) {
      const collapse = document.querySelector('.el-collapse')

      if (!collapse.contains(event.target)) {
        this.$store.commit('showMenu', false)
      }
    },
    handleRouterJump(pathName, sf) {
      if (pathName !== 'product') {
        this.activeName = '-2'
        this.$store.commit('showMenu', false)
        this.$router.push({
          name: pathName
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.menu {
  position: absolute;
  top: 50px;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 2001;
  .el-collapse-item {
    ::v-deep .el-collapse-item__header {
      padding: 0 0 0 16px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;

      &.is-active {
        font-weight: 600;
      }
    }
    .el-collapse-item__content {
      .solve-programme-menu {
        font-size: 14px;
        padding: 16px 0 0 24px;
        .hj-workbench-item {
          .item-head {
            line-height: 12px;
            padding-left: 6px;
            border-left: 2px solid #2d84ff;
            font-weight: 600;
            color: #2d84ff;
          }
          .item-content {
            margin-left: 10px;
            .programmes {
              margin-bottom: 6px;
              cursor: pointer;
              // margin-right: 32px;
              font-weight: 400;
              color: #333333;
              display: inline-block;
              width: 30%;
            }
          }
        }
      }
    }
  }
}
</style>