import request from '@/utils/request'

/**
 * 获取产品方案
 * @param {*} data 
 * @returns 
 */
export function getCase(data) {
  return request({
    url: '/IntendedUser/getCase',
    method: 'POST',
    data: data
  })
}

/**
 * 获取产品方案获取短信验证码
 * @param {*} data 
 * @returns 
 */
export function getCaseSmsCode(data) {
  return request({
    url: `/IntendedUser/getCaseSmsCode?mobile=${data.mobile}`,
    method: 'GET',
  })
}

/**
 * 保存模块信息
 * @param {*} data 
 * @returns 
 */
export function saveCase(data) {
  return request({
    url: '/IntendedUser/saveCase',
    method: 'POST',
    data: data
  })
}