<template>
  <div class="hj-home-tool" v-if="toolVisible">
    <div class="tool-item popover">
      <el-popover
        popper-class="communicate-popover"
        placement="left-start"
        width="76"
        trigger="hover"
        :close-delay="0"
      >
        <div>
          <img
            class="wx-img"
            src="@/assets/images/customer-service.png"
            alt=""
            @click="handleShowWeixin"
          />
          <p>微信扫一扫</p>
        </div>
        <div slot="reference" class="content">
          <wb-icon icon="telephone"></wb-icon>
          <p>客服小宏</p>
        </div>
      </el-popover>
    </div>

    <div class="tool-item popover">
      <el-popover
        popper-class="phone-popover"
        placement="left-start"
        width="120"
        trigger="hover"
        offset="-10"
        :close-delay="0"
      >
        <p @click="handleCall">400-0330-089</p>
        <div slot="reference" class="content">
          <wb-icon icon="consulting"></wb-icon>
          <p>电话咨询</p>
        </div>
      </el-popover>
    </div>

    <div class="tool-item btn" @click="handleReservationService">
      <wb-icon icon="appointment"></wb-icon>
      <p>预约演示</p>
    </div>

    <div class="tool-item popover" v-if="!IsPhone()">
      <el-popover
        popper-class="communicate-popover"
        placement="left-start"
        width="100"
        trigger="hover"
        :close-delay="0"
      >
        <div>
          <p><wb-icon icon="wx"></wb-icon>微信扫一扫</p>

          <vue-qr
            class="erweima"
            :text="currentUrl"
            :size="100"
            :margin="0"
          ></vue-qr>
        </div>
        <div slot="reference" class="content">
          <wb-icon icon="share"></wb-icon>
          <p>分享链接</p>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import { infos } from './data/info.js'
import { getTypicalUsers } from '@/api/typicalUsers'
import VueQr from 'vue-qr'
import wx from 'weixin-js-sdk' // 引入wxjs
import { wxShare } from '@/api/user' // 分享的接口  传给后端一个地址(与域名一致)

export default {
  data() {
    return {
      toolVisible: false,

      QRImgUrl: '',
      QRlink: '',
      currentUrl: window.location.href
    }
  },
  components: {
    VueQr
  },
  watch: {
    $route: {
      handler(n, o) {
        this.getShareInfo()
        this.toolVisible =
          n.name != 'fullPdf' && n.name != 'insidePlist' ? true : false

        this.currentUrl = window.location.href
      }
    }
  },
  mounted() {
    this.getShareInfo()
  },
  methods: {
    IsPhone() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info)
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone
    },
    /**
     * 手机唤醒
     */
    handleCall() {
      let docWidth = document.body.clientWidth
      if (docWidth > 572) return

      window.location.href = 'tel://400-0330-089'
    },
    /**
     * 点击打开微信客服
     */
    handleShowWeixin() {
      let docWidth = document.body.clientWidth
      if (docWidth > 572) return

      window.location.href =
        'https://work.weixin.qq.com/kfid/kfc8c51a4435138b65f'
    },
    /**
     * 预约演示
     */
    handleReservationService() {
      this.$emit('showPre')
    },
    copyUrl() {
      var cInput = document.createElement('input')
      cInput.value = window.location.href
      document.body.appendChild(cInput)
      cInput.select() // 选取文本框内容

      document.execCommand('copy')

      this.$message({
        type: 'success',
        message: '复制成功'
      })
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput)
    },
    // vue调用微信的自定义分享
    async getShareInfo() {
      let isMobile =
        navigator.userAgent.match(/mobile/i) &&
        !navigator.userAgent.match(/iPad/i)

      // if (!isMobile) return

      let params = {
        url: window.location.href
      }
      wxShare(params).then(async (res) => {
        if (res.success) {
          const config = res

          wx.config({
            debug: false,
            appId: config.appId, // appID 公众号的唯一标识
            timestamp: config.timestamp, // 生成签名的时间戳
            nonceStr: config.noncestr, //  生成签名的随机串
            signature: config.signature, // 生成的签名
            jsApiList: [
              'checkJsApi',
              'onMenuShareTimeline',
              'onMenuShareAppMessage' // 4
              // 'updateAppMessageShareData',
              // 'updateTimelineShareData',
              // 'onMenuShareTimeline',
              // 'onMenuShareAppMessage',
              // 'onMenuShareQQ',
              // 'onMenuShareWeibo',
              // 'onMenuShareQZone'
            ]
          })
          if (Object.keys(infos).length == 19) {
            let { data: caseList } = await getTypicalUsers({
              type: '',
              isCase: 1,
              kind: ''
            })
            caseList.forEach((item) => {
              item.list.forEach((casc) => {
                infos['userStories?userName=' + casc.caseUrl] = {}
                infos['userStories?userName=' + casc.caseUrl].title =
                  '宏景云平台-' + casc.name
                infos['userStories?userName=' + casc.caseUrl].desc =
                  casc.userDesc
              })
            })
          }

          let lArr = location.href.split('/')
          let objKey = decodeURIComponent(lArr[lArr.length - 1])

          await wx.ready(() => {
            var shareData = {
              title: infos[objKey].title,
              desc: infos[objKey].desc,
              link: window.location.href,
              imgUrl: 'http://www.hjhrcloud.com/assets/images/logo_hjy.jpg' // 分享出去的图片地址
            }
            //点击要去分享
            wx.onMenuShareTimeline(shareData)
            wx.onMenuShareAppMessage(shareData)
            // wx.updateAppMessageShareData(shareData)
            // wx.updateTimelineShareData(shareData)
            // wx.onMenuShareQQ(shareData)
            // wx.onMenuShareWeibo(shareData)
            // wx.onMenuShareQZone(shareData)
          })
          wx.error(function (res) {
            console.log(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.communicate-popover {
  min-width: 126px !important;
  display: flex;
  justify-content: center;
  div {
    text-align: center;
    p {
      line-height: 26px;
      color: #666;
      font-size: 12px;
    }

    .copy-url {
      cursor: pointer;
    }
    .wx-img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
<style lang="scss" scoped>
.hj-home-tool {
  z-index: 2000;
  position: absolute;
  right: 8px;
  bottom: 100px;
  .tool-item {
    &.btn {
      display: flex;
      cursor: pointer;
    }
    width: 70px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid #dedede;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #2d84ff;
    margin-top: 10px;

    ::v-deep .el-popover__reference-wrapper {
      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    svg {
      font-size: 32px;
    }
    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 17px;
    }

    &:hover {
      background: #2d84ff;
      color: #fff;
    }
  }
}

/* 页面宽度小于572px
------------------------------- */
@media screen and (max-width: 572px) {
  .hj-home-tool {
    bottom: 200px;
  }
}
</style>