<template>
  <!-- 电脑菜单 -->
  <div class="el-menu-horizontal-warp">
    <el-menu
      :default-active="getRouteIndex"
      class="hj-gf-menu"
      mode="horizontal"
      text-color="rgba(51, 51, 51, 1)"
      active-text-color="rgb(45, 132, 255 )"
    >
      <el-menu-item
        v-for="menu in menuList"
        :class="menu.path"
        :index="'/' + menu.path"
        :key="menu.menuItem"
        @click.native="handleSelect(menu)"
      >
        {{ menu.menuItem }}
        <!-- 二级菜单 -->
        <div v-if="menu.children" class="sub-menu">
          <span
            v-for="el in menuList[1].children"
            :key="el.title"
            class="hj-workbench-item"
          >
            <div class="pb5 lh32">
              <span class="shu"></span>
              {{ el.sortTitle }}
            </div>
            <div class="item-children">
              <div v-for="sort in el.selectItem" :key="sort.sortTitle">
                <span
                  class="m-item"
                  @click.stop="handleRouterJump(sort.pathName)"
                >
                  <wb-icon :icon="'programme-icon-' + sort.icon"></wb-icon>
                  {{ sort.title }}
                </span>
              </div>
            </div>
          </span>
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'navMenuHorizontal',
  computed: {
    getRouteIndex() {
      let routeName = this.$route.path
      routeName = routeName.split('/')
      return '/' + routeName[1]
    },
    menuList() {
      return this.$store.state.menuList
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.addEventToMenu()
    })
  },
  methods: {
    addEventToMenu() {
      let menu = document.querySelector('.sub-menu')
      let productBox = document.querySelector('.product')

      productBox.addEventListener('mouseover', () => {
        menu.style.display = 'block'
      })
      productBox.addEventListener('mouseout', () => {
        menu.style.display = 'none'
      })
    },
    handleSelect(key) {
      if (key.path !== 'product') {
        return this.$router.push({ name: key.path })
      }
    },
    handleRouterJump(pathName, sf) {
      let menu = document.querySelector('.sub-menu')
      menu.style.display = 'none'

      if (pathName) {
        this.$router.push({
          name: pathName
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sub-menu {
  position: fixed;
  left: calc(50% - 410px);
  border-radius: 8px;
  box-shadow: 0px 0px 29px 6px rgba(0, 0, 0, 0.1);
  cursor: default;
  display: none;
  padding-left: 80px !important;
  padding: 20px;
  background: #fff;
  z-index: 9;
  font-size: 18px;
  font-weight: bold;
  color: rgba(45, 132, 255, 1) !important;
  line-height: 20px;
  .lh32 {
    line-height: 32px;
  }
  .shu {
    display: inline-block;
    height: 18px;
    border: 2px solid rgba(45, 132, 255, 1);
    border-radius: 2px;
    margin-right: 8px;
  }
  span {
    font-size: 16px;
    margin-right: 60px;
    color: rgba(51, 51, 51, 1);
    .wb-icon {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }
  .item-children {
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    display: -ms-flexbox;

    margin-left: 16px;
    width: 710px;
    font-weight: normal;
    div {
      width: 26%;
      margin-bottom: 16px;
    }
    .m-item {
      cursor: pointer;
      &:hover {
        color: rgb(45, 132, 255);
      }
    }
  }
}
.el-menu-horizontal-warp {
  height: 100%;
  .hj-gf-menu {
    height: 100%;
    .el-menu-item:hover {
      color: rgb(45, 132, 255) !important;
      border-bottom-color: rgb(45, 132, 255) !important;
    }
    li {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .el-menu-item {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}
</style>
<style lang="scss">
.el-menu-horizontal-warp .el-submenu__title {
  display: flex;
  align-items: center;
  height: 100% !important;
  font-size: 16px;
}
.el-menu--popup .hj-workbench-item {
  padding-left: 80px !important;
  cursor: auto;
  height: auto !important;
  overflow: hidden;
  &:first-of-type {
    margin-top: 42px;
  }
  div {
    font-size: 18px;
    font-weight: bold;
    color: rgba(45, 132, 255, 1) !important;
    line-height: 20px;
    .shu {
      height: 19px;
      border: 2px solid rgba(45, 132, 255, 1);
      border-radius: 2px;
      margin-right: 8px;
    }
  }
  span {
    cursor: pointer;
    font-size: 16px;
    display: inline-block;
    margin-right: 60px;
    color: rgba(51, 51, 51, 1) !important;
    display: flex;
    align-items: center;
    float: left;
    .wb-icon {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }
}

/* 页面宽度小于768px
------------------------------- */
@media screen and (max-width: 768px) {
  .el-menu-horizontal-warp {
    display: none;
  }
}
/* 页面宽度大于768px小于1024px
------------------------------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .el-menu-item {
    padding: 0 7px !important;
  }
}
</style>
<style>
.hj-workbench-item.el-menu-item {
  margin-bottom: 24px;
  font-size: 16px;
}
</style>