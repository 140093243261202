<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" />

    <!-- 右下角工具 -->
    <right-tool @showPre="handleReservationService"></right-tool>

    <!-- 预约演示 -->
    <Dialog
      v-if="dialogVisible"
      title="预约演示"
      :dialogVisible="dialogVisible"
      :screenWidth="screenWidth"
      @cancel="dialogVisible = false"
    ></Dialog>

    <!-- 预约演示 -->
    <info-dialog
      v-if="infoDialog"
      :dialogVisible="infoDialog"
      :screenWidth="screenWidth"
      @cancel="handleCancel"
    ></info-dialog>
  </div>
</template>

<script>
import { showRegister } from '@/api/user'

import screenfull from 'screenfull'

import Cookies from 'js-cookie'
import Dialog from './components/dialog/Dialog.vue'
import InfoDialog from './components/infoDialog/InfoDialog.vue'
import RightTool from './components/rightTool/RightTool'

export default {
  data() {
    return {
      dialogVisible: false,
      screenWidth: null
    }
  },
  computed: {
    infoDialog() {
      return this.$store.state.infoDialogVisible
    }
  },
  components: {
    Dialog,
    InfoDialog,
    RightTool
  },
  created() {
    var lk = 'tsid'
    var logined = Cookies.get(lk)
    if (logined && !window.location.pathname.includes('insidePlist')) {
      window.location.href = window._cfg.API_BASE
    }
  },
  mounted() {
    this.limitRegisterBtn()

    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth

    window.addEventListener('resize', () => {
      this.screenWidth = document.body.clientWidth
    })
  },
  methods: {
    /**
     * 是否展示注册按钮
     */
    limitRegisterBtn() {
      showRegister().then((res) => {
        if (res.success) {
          if (
            res.data == 0 &&
            window.location.host.indexOf('hjypt') == -1 &&
            window.location.host.indexOf('demo') == -1
          ) {
            this.$store.state.showRegisterBtn = true
          } else {
            this.$store.state.showRegisterBtn = false
          }
        }
      })
    },
    /**
     * 取消
     */
    handleCancel(showDa) {
      this.$store.state.infoDialogVisible = false

      if (showDa) {
        if (screenfull.isEnabled && !screenfull.isFullscreen) {
          screenfull.toggle()
        }

        this.$router.push({
          path: '/fullPdf/' + this.$store.state.firstClickProgramme
        })
      }
    },
    handleReservationService() {
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/css/style-variable.scss';
@import '@/assets/css/style-overlay.scss';
@import '@/assets/css/public.scss';
#app {
  font-family: Microsoft YaHei, PingFangSC-Regular, PingFang SC,
    YaHei-Consolas-Hybrid, YaHei-Consolas;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.report {
  color: #606266;
}
.report:hover {
  color: #66b1ff;
}
.scroll-touch {
  -webkit-overflow-scrolling: touch;
}
@media screen and (orientation: portrait) {
  .nextMonth {
    transform: rotate(90deg) !important;
    -ms-transform: rotate(90deg) !important;
    -moz-transform: rotate(90deg) !important;
    -webkit-transform: rotate(90deg) !important;
    -o-transform: rotate(90deg) !important;
  }
}
.el-popover.phone-popover {
  background: #2d84ff !important;
  border-color: #2d84ff;
  p {
    white-space: nowrap;
    text-align: center;
    color: #fff;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 12px;
    line-height: 17px;
  }
  .popper__arrow {
    border-left-color: #2d84ff !important;

    &::after {
      border-left-color: #2d84ff !important;
    }
  }
}
</style>

<style scoped lang="scss">
</style>


<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-button {
    width: 80px;
  }
  .el-button--default,
  .el-button--primary {
    min-width: 0 !important;
  }
}
</style>
<style lang="scss">
.reservation-service {
  max-width: 580px;
  .el-dialog__header {
    background-color: #2d84ff;
    .wb-dialog-title {
      color: #ffffff;
      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 46px;
      }
      .desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-bottom: 38px;
      }
    }
  }
  .el-dialog__body {
    .wb-dialog-body {
      .el-form {
        .el-form-item__label {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
        }
        .el-form-item__content {
          .el-checkbox {
            width: 100px;
          }

          .el-input__inner {
            line-height: normal;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    .el-button {
      padding: 11px !important;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}
/* 页面宽度小于768px
------------------------------- */
@media screen and (max-width: 768px) {
  .reservation-service {
    max-width: none;
    margin: 0;
    margin-top: 0 !important;
    height: auto;
    overflow: auto;
  }
  .wb-dialog .wb-dialog-body {
    padding: 10px 10px 0 0;
  }
}

/* 页面宽度小于572px
------------------------------- */
@media screen and (max-width: 572px) {
  .tool-item {
    width: 40px !important;
    height: 40px !important;
    svg {
      font-size: 20px !important;
    }
    p {
      display: none;
    }
  }
}
</style>