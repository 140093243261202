import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
import { warningClose } from '@/utils/message'

Vue.use(VueRouter)

const routes = [
  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/user/login/Login.vue")
  },
  // 注册
  {
    path: "/register/:id",
    name: "register",
    component: () => import(/* webpackChunkName: "register" */ "@/views/user/register/Register.vue")
  },
  // 绑定微信
  {
    path: "/loginAndbindAccount",
    name: "bindWx",
    component: () => import(/* webpackChunkName: "bindWx" */ "@/views/user/bindWx/BindWx.vue")
  },
  // 典型用户 - 不用登陆
  {
    path: "/insidePlist",
    name: 'insidePlist',
    component: () => import(/* webpackChunkName: "insidePlist" */ "@/views/product/list/insidePlist.vue"),
  },
  {
    path: '/',
    name: '',
    component: layout,
    redirect: '/home',
    children: [
      // 首页
      {
        path: "/home",
        name: "home",
        component: () => import(/* webpackChunkName: "HomeView" */ "@/views/home/HomeView.vue")
      },
      // 解决方案 - 暂时弃用(产品介绍)
      {
        path: "/product",
        name: 'product',
        component: () => import("@/views/product/index.vue"),
        children: [
          // 干部管理 - 民主测评
          {
            path: "democraticEvaluation",
            name: 'democraticEvaluation',
            component: () => import(/* webpackChunkName: "democraticEvaluation" */ "@/views/product/carde/DemocraticEvaluation.vue"),
            meta: {
              name: '民主测评'
            }
          },
          // 干部管理 - 民主推荐
          {
            path: "democraticRecommend",
            name: 'democraticRecommend',
            component: () => import(/* webpackChunkName: "democraticRecommend" */ "@/views/product/carde/DemocraticRecommend.vue"),
            meta: {
              name: '民主推荐'
            }
          },
          // 干部管理 - 选拔任用
          // {
          //   path: "selectionAppointment",
          //   name: 'selectionAppointment',
          //   component: () => import(/* webpackChunkName: "selectionAppointment" */ "@/views/home/HomeView.vue"),
          //   meta: {
          //     name: '选拔任用'
          //   }
          // },
          // 干部管理 - 领导力评估反馈
          {
            path: "leadershipAssessment",
            name: 'leadershipAssessment',
            component: () => import(/* webpackChunkName: "leadershipAssessment" */ "@/views/product/carde/LeadershipAssessment.vue"),
            meta: {
              name: '领导力评估反馈'
            }
          },
          // 干部管理 - 干部信息
          {
            path: "cadreInformation",
            name: 'cadreInformation',
            component: () => import(/* webpackChunkName: "cadreInformation" */ "@/views/product/carde/CadreInformation.vue"),
            meta: {
              name: '干部信息'
            }
          },
          // 干部管理 - 干部管理
          {
            path: "cadreSelection",
            name: 'cadreSelection',
            component: () => import(/* webpackChunkName: "cadreSelection" */ "@/views/product/carde/CadreSelection.vue"),
            meta: {
              name: '干部管理'
            }
          },
          // 干部管理 - 干部考核
          // {
          //   path: "cadreAssessment",
          //   name: 'cadreAssessment',
          //   component: () => import(/* webpackChunkName: "cadreAssessment" */ "@/views/product/carde/CadreAssessment.vue"),
          //   meta: {
          //     name: '干部考核'
          //   }
          // },
          // 干部管理 - 干部监督
          // {
          //   path: "cadreSupervision",
          //   name: 'cadreSupervision',
          //   component: () => import(/* webpackChunkName: "cadreSupervision" */ "@/views/product/carde/CadreSupervision.vue"),
          //   meta: {
          //     name: '干部监督'
          //   }
          // },
          // 人才评价 - 职称评审
          {
            path: "titleReview",
            name: 'titleReview',
            component: () => import(/* webpackChunkName: "titleReview" */ "@/views/product/title/TitleReview.vue"),
            meta: {
              name: '职称评审'
            }
          },
          // 人才评价 - 同行评议
          {
            path: "peersComment",
            name: 'peersComment',
            component: () => import(/* webpackChunkName: "peersComment" */ "@/views/product/title/PeersComment.vue"),
            meta: {
              name: '同行评议'
            }
          },
          // 人才评价 - 在线考试
          {
            path: "onlineExam",
            name: 'onlineExam',
            component: () => import(/* webpackChunkName: "onlineExam" */ "@/views/product/title/OnlineExam.vue"),
            meta: {
              name: '在线考试'
            }
          },
          // 绩效管理 - 绩效管理
          {
            path: "performanceManagement",
            name: 'performanceManagement',
            component: () => import(/* webpackChunkName: "performanceManagement" */ "@/views/product/achievements/PerformanceManagement.vue"),
            meta: {
              name: '绩效管理'
            }
          },
          // 绩效管理 - 360考核
          {
            path: "360Assessment",
            name: '360Assessment',
            component: () => import(/* webpackChunkName: "360Assessment" */ "@/views/product/achievements/ThreeAssessment.vue"),
            meta: {
              name: '360考核'
            }
          },
        ]
      },
      // 解决方案列表
      {
        path: "/productList",
        name: 'productList',
        component: () => import(/* webpackChunkName: "productList" */ "@/views/product/list/ProductList.vue"),
      },
      // 合作伙伴
      {
        path: "/cooperativePartner",
        name: 'cooperativePartner',
        component: () => import(/* webpackChunkName: "cooperativePartner" */ "@/views/partner/CooperativePartner.vue"),
      },
      // 合作伙伴列表
      {
        path: "/partnerList",
        name: 'partnerList',
        component: () => import(/* webpackChunkName: "typicalUsers" */ "@/views/partner/PartnerList.vue")
      },
      // 典型用户
      {
        path: "/typicalUsers",
        name: 'typicalUsers',
        component: () => import(/* webpackChunkName: "typicalUsers" */ "@/views/typicalUsers/TypicalUsers.vue"),
        meta: {
          keepAlive: true
        }
      },
      // 典型用户 - 用户案例
      {
        path: "/typicalUsers/userStories",
        name: 'userStories',
        component: () => import(/* webpackChunkName: "typicalUsers" */ "@/views/typicalUsers/UserStories.vue")
      },
      // 关于我们
      {
        path: "/aboutUs",
        name: 'aboutUs',
        component: () => import(/* webpackChunkName: "aboutUs" */ "@/views/aboutUs/AboutUs.vue")
      },

    ]
  },
  {
    path: '/fullPdf/:pdfName',
    name: 'fullPdf',
    component: () => import(/* webpackChunkName: "fullPdf" */ "@/views/fullPdf.vue")
  },
]
const originalReplace = VueRouter.prototype.replace;
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  base: '/mh',
  routes
})

router.beforeEach((to, from, next) => {
  warningClose()
  next()
});

router.afterEach(() => {
  let scrollWrap = document.querySelector('.el-scrollbar__wrap')

  if (scrollWrap) {
    if (scrollWrap.scrollTo) {
      scrollWrap.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      // 兼容写法：
      scrollWrap.scrollTo = function (option) {
        scrollWrap.scrollTop = option.top
      }
      scrollWrap.scrollTo({ top: 0 })
    }
  }

});

export default router
