<template>
  <el-header class="layout-header">
    <NavBarsIndex />
  </el-header>
</template>

<script>
import NavBarsIndex from '../navBars/index.vue'
export default {
  name: 'layoutHeader',
  components: { NavBarsIndex },
  data() {
    return {}
  }
}
</script>
