import Vue from "vue";
import { message } from "@/utils/message.js";
import "../../theme/index.css";
import {
    MessageBox,
    Loading,
    Container,
    Header,
    Main,
    Backtop,
    Scrollbar,
    Icon,
    Button,
    Menu,
    MenuItem,
    MenuItemGroup,
    Submenu,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Card,
    Row,
    Col,
    Carousel,
    CarouselItem,
    Tabs,
    TabPane,
    Form,
    FormItem,
    Input,
    Table,
    TableColumn,
    Tag,
    Checkbox,
    CheckboxGroup,
    Tooltip,
    Steps,
    Step,
    Collapse,
    CollapseItem,
    Dialog,
    Link,
    Progress,
    Popover
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Loading);
Vue.use(Scrollbar);

Vue.use(Popover);
Vue.use(Progress);
Vue.use(Link);
Vue.use(Dialog);
Vue.use(CollapseItem);
Vue.use(Collapse);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Tooltip);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tag);
Vue.use(TableColumn);
Vue.use(Table);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(Form);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Backtop);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Submenu);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.prototype.$message = message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
