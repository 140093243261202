<template>
  <div class="hj-gf-logo">
    <em :class="showCollapse ? 'on' : ''" @click="handleShowMenu">
      <i class="i1"></i><i class="i2"></i><i class="i3"></i
    ></em>
    <img src="@/assets/images/hj-logo.png" @click="onThemeConfigChange" />
  </div>
</template>

<script>
export default {
  name: 'layoutLogo',
  data() {
    return {}
  },
  computed: {
    showCollapse() {
      return this.$store.state.menuVisible
    }
  },
  methods: {
    // logo 点击实现回到首页
    onThemeConfigChange() {
      this.$router.push({ name: 'home' })
      this.$store.commit('showMenu', false)
    },
    handleShowMenu() {
      this.$store.commit('showMenu', !this.showCollapse)
    }
  }
}
</script>

<style scoped lang="scss">
.hj-gf-logo {
  display: flex;
  align-items: center;

  img {
    height: 50px;
    width: 120px;
    cursor: pointer;
  }
  em {
    width: 34px;
    height: 34px;
    display: none;
    overflow: hidden;
    float: left;
    position: relative;
    cursor: pointer;
    i {
      cursor: pointer;
      width: 60%;
      display: block;
      overflow: hidden;
      height: 2px;
      background-color: #409eff;
      position: absolute;
      left: 15%;
      top: 10px;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      &.i1 {
        top: 10px;
      }
      &.i2 {
        top: 18px;
      }
      &.i3 {
        top: 26px;
      }
    }
    &.on {
      i.i1 {
        width: 60%;
        left: 20%;
        transform: translateY(8px) rotate(45deg);
        -ms-transform: translateY(8px) rotate(45deg);
        -moz-transform: translateY(8px) rotate(45deg);
        -webkit-transform: translateY(8px) rotate(45deg);
        -o-transform: translateY(8px) rotate(45deg);
      }
      i.i2 {
        opacity: 0;
      }
      i.i3 {
        width: 60%;
        left: 20%;
        transform: translateY(-8px) rotate(-45deg);
        -ms-transform: translateY(-8px) rotate(-45deg);
        -moz-transform: translateY(-8px) rotate(-45deg);
        -webkit-transform: translateY(-8px) rotate(-45deg);
        -o-transform: translateY(-8px) rotate(-45deg);
      }
    }
  }
}

/* 页面宽度小于768px
------------------------------- */
@media screen and (max-width: 768px) {
  .layout-header {
    .hj-gf-logo {
      img {
        width: 78px;
        height: 30px;
      }
      em {
        display: block;
      }
    }
  }
}
</style>
