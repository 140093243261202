export function validateSfz(rule, value, callback) {
  if (value) {
    function validataCredentials(num) {
      var msg, boo; //  msg身份证验证友情提示,boo返回值
      var num = num.toUpperCase(); // 身份证为末尾可能是X
      //   身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，
      //   最后一位是校验位，可能为数字或字符X。
      const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
      const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
      var nTemp = 0,
        i;
      if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))) {
        //  输入的身份证号长度不对，或者号码不符合规定;
        return [boo = false, msg = "请输入正确的身份证"];
      }
      //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      //   下面分别分析出生日期和校验位
      var len, re;
      len = num.length;
      if (len == 15) {
        re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
        var arrSplit = num.match(re);
        //   检查生日日期是否正确
        var dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
        var bGoodDay;
        bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
        if (!bGoodDay) {
          //   输入的身份证号里出生日期不对！
          return [boo = false, msg = "请输入正确的身份证"];

        } else {
          //   将15位身份证转成18位
          //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。   
          num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6);
          for (i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          num += arrCh[nTemp % 11];
          return [boo = true, msg = num];
        }
      }
      if (len == 18) {
        re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
        var arrSplit = num.match(re);
        //  检查生日日期是否正确
        var dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
        var bGoodDay;
        bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
        if (!bGoodDay) {
          //  输入的身份证号里出生日期不对！ 

          return [boo = false, msg = " 请输入正确的身份证"];
        } else {
          //  检验18位身份证的校验码是否正确。
          //  校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
          var valnum;
          for (i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          valnum = arrCh[nTemp % 11];
          if (valnum != num.substr(17, 1)) {
            //  18位身份证的校验码不正确！
            return [boo = false, msg = "请输入正确的身份证"];
          }
          return [boo = true, msg = "验证成功"]
        }
      }
      return [boo = false, msg = "请输入正确的身份证"];
    }
    setTimeout(() => {
      var res = validataCredentials(value);
      if (!res[0]) {
        callback(new Error(res[1]));
      } else {
        callback();
      }
    }, 0)
  } else {
    // return callback(new Error('身份证号码不能为空'));   
    //    return callback()
    return callback()
  }

};
export function validateSfz2(rule, value, callback) {
  if (value) {
    function validataCredentials(num) {
      var msg, boo; //  msg身份证验证友情提示,boo返回值
      var num = num.toUpperCase(); // 身份证为末尾可能是X
      //   身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，
      //   最后一位是校验位，可能为数字或字符X。
      const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
      const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
      var nTemp = 0,
        i;
      if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))) {
        //  输入的身份证号长度不对，或者号码不符合规定;
        return [boo = false, msg = "请输入正确的身份证"];
      }
      //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      //   下面分别分析出生日期和校验位
      var len, re;
      len = num.length;
      if (len == 15) {
        re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
        var arrSplit = num.match(re);
        //   检查生日日期是否正确
        var dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
        var bGoodDay;
        bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
        if (!bGoodDay) {
          //   输入的身份证号里出生日期不对！
          return [boo = false, msg = "请输入正确的身份证"];

        } else {
          //   将15位身份证转成18位
          //   校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。   
          num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6);
          for (i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          num += arrCh[nTemp % 11];
          return [boo = true, msg = num];
        }
      }
      if (len == 18) {
        re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
        var arrSplit = num.match(re);
        //  检查生日日期是否正确
        var dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
        var bGoodDay;
        bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
        if (!bGoodDay) {
          //  输入的身份证号里出生日期不对！ 

          return [boo = false, msg = " 请输入正确的身份证"];
        } else {
          //  检验18位身份证的校验码是否正确。
          //  校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
          var valnum;
          for (i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          valnum = arrCh[nTemp % 11];
          if (valnum != num.substr(17, 1)) {
            //  18位身份证的校验码不正确！
            return [boo = false, msg = "请输入正确的身份证"];
          }
          return [boo = true, msg = "验证成功"]
        }
      }
      return [boo = false, msg = "请输入正确的身份证"];
    }
    var res = validataCredentials(value);
    if (!res[0]) {
      return false
    } else {
      return true
    }
  } else {
    // return callback(new Error('身份证号码不能为空'));   
    //    return callback()
    return false
  }

};

export function validatePhone(rule, value, callback) {
  if (value) {
    setTimeout(() => {
      const reg = /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,1,3,5-8])|(18[0-9])|166|198|199|(147))[0-9]{8}$/
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error('请输⼊正确的电话号'));
      }
    }, 0)
  } else {
    return callback('请输入电话号')
  }
};

//验证邮箱
export function validateEmail(rule, value, callback) {
  if (value) {
    console.log(value)
    setTimeout(() => {
      const reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (reg.test(value)) {
        callback()
      } else {
        return callback(new Error('请输⼊正确的邮箱'));
      }
    }, 0)
  } else {
    return callback();

  }
}