//兼容IE
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

import 'core-js'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/plugins/element"; // 引入 elememt ui
import "@/plugins/vant"; // 引入 vant ui
import "@/plugins/echarts"; // 引入 echarts
import "@/plugins/video";   // 引入 video
// import "@/plugins/vue-lazyload";   // 引入 vue-lazyload 暂时不要，vant里面自带lazyload

// import Vconsole from 'vconsole'
// new Vconsole()

import "@/assets/icons"; // svg

import '@/assets/css/index.scss';

Vue.config.productionTip = false

var vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vue