<template>
  <el-main class="layout-main">
    <el-scrollbar
      class="layout-scrollbar"
      ref="layoutScrollbarRef"
      :style="{ minHeight: `calc(100vh - 100px` }"
    >
      <!-- 内容 -->
      <div class="h100"><router-view /></div>

      <!-- 底部 -->
      <Footers />
    </el-scrollbar>
  </el-main>
</template>

<script>
import Headers from '../header/header.vue'
import Footers from '../footer/index.vue'
export default {
  name: 'layoutMain',
  components: { Headers, Footers },
  data() {
    return {}
  }
}
</script>
<style scoped>
.el-main {
  height: 90%;
}
</style>