import request from '@/utils/request'

/**
 * 预约服务
 * userName
mobilePhone
shortName
moduleType
 * @param {*} data 
 * @returns 
 */
export function makeAppointment(data) {
  return request({
    url: '/IntendedUser/add',
    method: 'POST',
    data: data
  })
}