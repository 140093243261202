<template>
  <div class="hj-gf-user">
    <div class="btn-pc">
      <el-button type="primary" round key="signIn" @click="handleToSinIn">
        {{ '登录' }} </el-button
      ><el-dropdown
        placement="bottom"
        @command="handleCommand"
        v-if="registerVisiable"
      >
        <span class="el-dropdown-link">
          <el-button round key="register">
            {{ '注册' }}
          </el-button>
        </span>
        <el-dropdown-menu slot="dropdown" class="hj-register-dropdown">
          <el-dropdown-item command="tenant">租户注册</el-dropdown-item>
          <el-dropdown-item command="personal">个人注册</el-dropdown-item>
          <!-- <el-dropdown-item command="service">服务商注册</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>

      <span class="seize-a-seat" v-else></span>
    </div>

    <div class="btn-mobile">
      <el-button type="primary" round key="signIn" @click="handleToSinInMobile">
        {{ '登录/注册' }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'layoutBreadcrumbUser',
  data() {
    return {}
  },
  computed: {
    registerVisiable() {
      return this.$store.state.showRegisterBtn
    }
  },
  mounted() {},
  methods: {
    /**
     * 登录
     */
    handleToSinIn() {
      this.$router.push({ name: 'login' })
      // console.log('登录')
    },
    /**
     * 移动端 - 登录、注册
     */
    handleToSinInMobile() {
      let time = new Date().getTime()
      window.location.href = window._cfg.API_BASE + '/mbw/login?_t=' + time
    },
    /**
     * 电脑注册
     */
    handleCommand(command) {
      this.$router.push({
        name: 'register',
        params: { id: command }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.hj-gf-user {
  .el-button {
    margin: 0 10px;
    text-align: center;
  }
}

.seize-a-seat {
  display: inline-block;
  width: 90px;
}
.btn-mobile {
  display: none;
  .el-button {
    font-size: 12px;
    padding: 6px 12px !important;
  }
}
/* 页面宽度小于552px
------------------------------- */
@media screen and (max-width: 768px) {
  .hj-gf-user {
    .btn-pc {
      display: none;
    }
    .btn-mobile {
      display: block;
      .el-button {
        margin: 0;
      }
    }
  }
}
</style>
<style>
/* 消除小三角 */
.hj-register-dropdown[x-placement^='bottom'] .popper__arrow {
  border: none;
}
.hj-register-dropdown[x-placement^='bottom'] .popper__arrow::after {
  border: none;
}
</style>
