import Vue from "vue";

import WbIcon from "@/components/icon/WbIcon.vue"; // svg component

/* register globally */
Vue.component("wb-icon", WbIcon);

const req = require.context("./svg", false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);
