<template>
  <div class="layout-navbars-container">
    <div class="layout-navbars-breadcrumb-index hj-gf-content">
      <Logo />
      <Menu />
      <User />
    </div>
  </div>
</template>

<script>
import Logo from './breadcrumb/logo'
import User from './breadcrumb/user'
import Menu from './breadcrumb/menu'
export default {
  name: 'layoutNavBars',
  components: { Logo, User, Menu },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.layout-navbars-container {
  box-shadow: 1px 3px 20px 1px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--bg-topBar);
  border-bottom: 1px solid #dedede;
}
.layout-navbars-breadcrumb-index {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-topBar);
}

@media screen and (min-width: 768px) and (max-width: 830px) {
  .layout-navbars-breadcrumb-index {
    width: 100%;
  }
}
</style>
