<template>
  <div class="layout-footer">
    <div class="layout-footer-warp hj-gf-content" v-if="showCode">
      <!-- 底部左侧-公司信息 -->
      <div class="hj-gf-ft-left">
        <ul class="hj-gf-info">
          <li v-for="item in hjLeftInfo" :key="item.value">
            <wb-icon :icon="item.name"></wb-icon>
            <span @click="openUrl(item.name, item.value)">{{
              item.value
            }}</span>
          </li>
        </ul>
      </div>

      <!-- 底部右侧-二维码 -->
      <div class="hj-gf-ft-right">
        <ul>
          <li
            v-for="item in hjRightInfo"
            :key="item.title"
            @click="jumpToCode(item.url)"
          >
            <img class="mb8" :src="item.src" alt="" />
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="hj-icp-number">京ICP备14026361号-2</div>
  </div>
</template>

<script>
export default {
  name: 'layoutFooter',
  props: {
    showCode: {
      default: true
    }
  },
  data() {
    return {
      hjLeftInfo: [
        {
          name: 'telephone',
          value: window.settingURL.hjInfo.telephone
        },
        {
          name: 'website',
          value: window.settingURL.hjInfo.website
        },
        {
          name: 'address',
          value: window.settingURL.hjInfo.address
        }
      ],
      hjRightInfo: [
        // {
        //   title: '宏景云平台客服',
        //   src: require('../../assets/images/qrCode/hj-customer-code.png'),
        //   url: ''
        // },
        {
          title: '玩转宏景云',
          src: require('../../assets/images/qrCode/hj-wanzhuan-code.png'),
          url: window.settingURL.wanzhuanUrl
        },
        {
          title: '宏景云平台公众号',
          src: require('../../assets/images/qrCode/hj-platform-code.png'),
          url: ''
        }
      ]
    }
  },
  methods: {
    jumpToCode(url) {
      if (url) window.open(window.location.protocol + '//' + url, '_blank', '')
    },
    openUrl(name) {
      const obj = {
        telephone: () => {
          let docWidth = document.body.clientWidth
          if (docWidth > 572) return

          window.location.href = 'tel://400-0330-089'
        },
        website: () =>
          window.open('//' + window.settingURL.hjInfo.website, '_blank', '')
      }

      obj[name] ? obj[name]() : ''
    }
  }
}
</script>

<style scoped lang="scss">
.layout-footer {
  width: 100%;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  &-warp {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    .hj-gf-ft-left {
      margin-top: 20px;
      li {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 24px;
        svg {
          font-size: 24px;
          margin-right: 8px;
        }
        .el-link.el-link--primary {
          color: rgb(255, 255, 255);
        }
        .el-link.el-link--primary:hover {
          color: #6d86f5;
        }
      }

      & li:last-of-type {
        margin-bottom: 0;
      }
    }

    .hj-gf-ft-right {
      ul {
        display: flex;
        li {
          text-align: center;
          margin-left: 24px;
          img {
            width: 120px;
          }
        }

        li:first-of-type {
          margin: 0;
        }
      }
    }
  }
  .hj-icp-number {
    height: 67px;
    line-height: 67px;
    text-align: center;
    border-top: 1px solid rgba(151, 151, 151, 1);
  }
}
@media screen and (max-width: 768px) {
  .layout-footer-warp {
    flex-direction: column-reverse;
  }
  .layout-footer-warp .hj-gf-ft-right ul {
    justify-content: space-around;
    li {
      width: 28vw;
      img {
        width: 100%;
      }
    }
  }
  .hj-gf-info {
    li:nth-child(1) {
      float: left;
      margin-right: 20px;
    }
  }
}
@media screen and (max-width: 478px) {
  .hj-gf-info {
    li {
      float: left;
    }
  }
}
</style>
