
import request from '@/utils/request'

/**
 * @param {*} data 
 * @returns 
 */
export function getTypicalUsers(data) {
  return request({
    url: '/typicalUsers/web/getTypicalUsers',
    method: 'POST',
    data: data
  })
}

/**
 * @param {*} data 
 * @returns 
 */
export function getTypicalUserTypes(data) {
  return request({
    url: '/typicalUsers/web/getTypicalUserTypes',
    method: 'POST',
    data: data
  })
}