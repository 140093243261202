import { render, staticRenderFns } from "./Mbmenu.vue?vue&type=template&id=5b002998&scoped=true"
import script from "./Mbmenu.vue?vue&type=script&lang=js"
export * from "./Mbmenu.vue?vue&type=script&lang=js"
import style0 from "./Mbmenu.vue?vue&type=style&index=0&id=5b002998&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b002998",
  null
  
)

export default component.exports