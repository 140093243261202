export const infos = {
  login: {
    title: "宏景云平台-登录",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  register: {
    title: "宏景云平台-注册",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  loginAndbindAccount: {
    title: "宏景云平台-绑定微信",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  home: {
    title: "宏景云平台",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  cooperativePartner: {
    title: "宏景云平台-合作伙伴",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  partnerList: {
    title: "宏景云平台-合作伙伴",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  aboutUs: {
    title: "宏景云平台-关于我们",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  productList: {
    title: "宏景云平台-解决方案",
    desc: "致力于打造中国人才发展生态链，让人尽其才，才尽其用！"
  },
  typicalUsers: {
    title: "宏景云平台-典型用户",
    desc: "总结了人才管理的典型用户，通过互联网技术，与宏景广大的用户进行分享，提升用户管理水平，降低用户总拥成本！"
  },
  democraticEvaluation: {
    title: "宏景云民主测评",
    desc: "适用于民主测评、民主评议、选拔任用等业务场景！"
  },
  democraticRecommend: {
    title: "宏景云民主推荐",
    desc: "适用于干部选拔、竞聘上岗、职称评审、评先推优等业务场景！"
  },
  '360Assessment': {
    title: "宏景云360考核",
    desc: "适用于年度考核、聘期考核等业务场景！"
  },
  cadreSelection: {
    title: "宏景云选拔任用",
    desc: "适用于干部任免、考察监督等业务场景！"
  },
  leadershipAssessment: {
    title: "宏景云领导力评估",
    desc: "适用于人才识别、能力提升、人才选拔、人才盘点等业务场景！"
  },
  titleReview: {
    title: "宏景云职称评审",
    desc: "适用于信息发布、申报人申报、材料审核、同行评议、会议评审、确认公示、总结分析等业务场景！"
  },
  peersComment: {
    title: "宏景云同行评议",
    desc: "适用于职称评审、人才项目、人才引进等业务场景！"
  },
  performanceManagement: {
    title: "宏景云绩效管理",
    desc: "适用于KPI+GS考核、综合考核等业务场景！"
  },
  cadreInformation: {
    title: "宏景云干部信息",
    desc: "适用于信息维护、查询浏览、花名册、统计分析、干部任免表、干部台账等业务场景！"
  },
  onlineExam: {
    title: "宏景云在线考试",
    desc: "适用于企事业单位招聘考试、培训考试、晋升考试、资格考试、选拔考试、知识竞赛等多种考试业务场景！"
  },


  // 典型用户案例 
  // casezhjt: {
  //   title: "宏景云平台-中国核工业集团有限公司",
  //   desc: "中核集团启用测评新模式"
  // },
  // casejsjt: {
  //   title: "宏景云平台-江苏交通控股有限公司",
  //   desc: "江苏交控|连续3年宏景云越用越顺，民主测评越用越深"
  // },
  // casebjhthk: {
  //   title: "宏景云平台-北京航空航天大学",
  //   desc: "北航携手宏景云，实现无纸化的职称评审"
  // },
  // casekydx: {
  //   title: "宏景云平台-中国矿业大学（北京）",
  //   desc: "四步带你走近矿业大学（北京）同行评议创新应用"
  // },
  // casebjjzdx: {
  //   title: "宏景云平台-北京建筑大学",
  //   desc: "北京建筑大学：“指尖上的民主测评”开启干部评价新模式"
  // },
  // casegb: {
  //   title: "宏景云平台-广东广播电视台",
  //   desc: "指尖上的民主测评 ——广东广播电视台宏景云民主测评系统使用纪略"
  // },
  // casekxcbs: {
  //   title: "宏景云平台-科学出版社(中国科技出版传媒股份有限公司)",
  //   desc: "科学出版社：宏景云平台打造高效年度考核 —— 北方大区战略客户部 刘朋"
  // },
  // caseztjt: {
  //   title: "宏景云平台-中铁交通投资集团有限公司",
  //   desc: "中铁交通：多组织人才评价创新实践"
  // },
  // casezgsl: {
  //   title: "宏景云平台-中国水利水电第八工程局有限公司",
  //   desc: "数字化助力水电八局人力资源管理转型升级——人力资源部"
  // },
  // casegdzy: {
  //   title: "宏景云平台-广东中烟工业有限责任公司",
  //   desc: "宏景云“黑科技”助力广东中烟考核测评"
  // },
  // caseyd: {
  //   title: "宏景云平台-人民邮电出版社",
  //   desc: "宏景云平台到底帮我们解决了哪些问题？"
  // },
  // casegd: {
  //   title: "宏景云平台-国电长源电力股份有限公司",
  //   desc: "效率与精细化提升利器——宏景云民主测评使用略记"
  // },
  // caseshbz: {
  //   title: "宏景云平台-上海宝冶集团有限公司",
  //   desc: "上海宝冶集团全员考核“一码搞定”"
  // },
  // casezghkyl: {
  //   title: "宏景云平台-中国航空油料集团有限公司",
  //   desc: "再次携手，迈步云端"
  // },
  // case11: {
  //   title: "宏景云平台-中铁十一局集团有限公司",
  //   desc: "宏景云平台 实现大跨越"
  // },
  // caseztjs: {
  //   title: "宏景云平台-中铁建设集团有限公司",
  //   desc: "中铁建设召开2019年度党组织书记抓基层党建工作述职评议会、二级单位总经理述职会"
  // },
  // casezjygj: {
  //   title: "宏景云平台-中交一公局集团有限公司",
  //   desc: "中交一公局集团有限公司 绩效管理信息化之路"
  // },
  // casezjlqjs: {
  //   title: "宏景云平台-中交路桥建设集团有限公司",
  //   desc: "利用移动互联开展年终民主测评工作"
  // },
  // casezgjzdbsj: {
  //   title: "宏景云平台-中国建筑东北设计研究院有限公司",
  //   desc: "革新干部测评方法，开启数字化时代勘探设计行业人才管理新模式！"
  // },
  // casezxtgt: {
  //   title: "宏景云平台-中信泰富特钢集团",
  //   desc: "中信特钢集团企业高管年度考评实现云模式"
  // },
  // casesgjt: {
  //   title: "宏景云平台-首钢京唐钢铁联合有限责任公司",
  //   desc: "实施在线测评让专业管理更便捷更高效"
  // },
  // casesdykd: {
  //   title: "宏景云平台-首都医科大学附属北京世纪坛医院",
  //   desc: "世纪坛医院云测评应用体会"
  // },
  // casebjszj: {
  //   title: "宏景云平台-北京市政建设集团有限责任公司",
  //   desc: "北京市政集团宏景云评估使用侧记"
  // },
  // casezgezjt: {
  //   title: "宏景云平台-中国二冶集团有限公司",
  //   desc: "公司召开2019年度总部机关部门述职述廉大会"
  // },
  // casezdtj: {
  //   title: "宏景云平台-中国电子科技集团公司第十五研究所/中电太极（集团）有限公司",
  //   desc: "电科太极：创新民主测评不“打太极”"
  // },
  // "userStories?userName=%2Fplatform%2Fhomepage%2Fcasehtml%2F20230918180256%2F%E5%AE%8F%E6%99%AF%E4%BA%91%E5%B9%B3%E5%8F%B0%2F%E5%AE%8F%E6%99%AF%E4%BA%91%E5%B9%B3%E5%8F%B0.index.html.html": {
  //   title: "宏景云平台-中国华电集团有限公司",
  //   desc: "中国华电ￜ职称评审上云端，提速增效看得见"
  // },
}