<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    custom-class="wb-dialog reservation-service get-info"
    ref="dialog"
    :width="dialogWidth"
    :before-close="cancel"
    :show-close="showClose"
    :destroy-on-close="true"
  >
    <!-- 标题 -->
    <div class="wb-dialog-title" slot="title">
      <p class="title">{{ title }}</p>
      <!-- <p class="desc">
        您可以专享一对一的产品演示与服务，顾问会为您提供合理的解决方案
      </p> -->
    </div>
    <!-- 自定义内容 -->
    <div class="wb-dialog-body">
      <el-form :model="form" :rules="rules" ref="userInfo">
        <el-form-item
          label="姓名"
          :label-width="formLabelWidth"
          prop="userName"
        >
          <el-input
            v-model="form.userName"
            placeholder="姓名"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="单位"
          :label-width="formLabelWidth"
          prop="shortName"
        >
          <el-input
            v-model="form.shortName"
            placeholder="单位名称"
            autocomplete="off"
            maxlength="30"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="电话"
          :label-width="formLabelWidth"
          prop="mobilePhone"
        >
          <el-input
            v-model="form.mobilePhone"
            type="tel"
            placeholder="联系电话"
            autocomplete="off"
            maxlength="11"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="验证码"
          :label-width="formLabelWidth"
          prop="smsCode"
          class="verification-content"
        >
          <el-input
            class="verification-code"
            v-model="form.smsCode"
            type="tel"
            placeholder="验证码"
            autocomplete="off"
          ></el-input>

          <el-button
            :disabled="countDownNumber != 0"
            @click="handleGetSmsCode"
            >{{ msg }}</el-button
          >
        </el-form-item>

        <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
          <el-input
            v-model="form.email"
            placeholder="邮箱"
            autocomplete="off"
            maxlength="30"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部操作 -->
    <span slot="footer" class="wb-dialog-footer" v-if="showFooter">
      <el-button :disabled="loading" @click="cancel" v-if="showCancel">{{
        cancelText
      }}</el-button>
      <el-button
        :loading="loading"
        type="primary"
        @click="comfirm"
        disabled
        v-if="isDisabled"
      >
        {{ comfirmText }}
      </el-button>
      <el-button
        :loading="loading"
        type="primary"
        @click="comfirm"
        v-else
        :disabled="comfirmDisabled"
        >{{ comfirmText }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import InfoDialog from './InfoDialog.js'
export default InfoDialog
</script>

<style lang="scss" scoped>
.verification-content {
  ::v-deep .el-form-item__content {
    display: flex;
    .verification-code {
      width: calc(100% - 100px);
    }

    .el-button {
      width: 100px;
      margin-left: 10px;
    }
  }
}

::v-deep .el-dialog {
  .el-button {
    width: 80px;
  }
  .el-button--default,
  .el-button--primary {
    min-width: 0 !important;
  }
}
</style>
<style lang="scss">
.get-info {
  .el-dialog__header {
    .wb-dialog-title {
      .title {
        padding-bottom: 30px;
      }
    }
  }
}

.reservation-service {
  .el-dialog__header {
    background-color: #2d84ff;
    .wb-dialog-title {
      color: #ffffff;
      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 46px;
      }
      .desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-bottom: 38px;
      }
    }
  }
  .el-dialog__body {
    .wb-dialog-body {
      .el-form {
        .el-form-item__label {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
        }
        .el-form-item__content {
          .el-checkbox {
            width: 100px;
          }

          .el-input__inner {
            line-height: normal;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    .el-button {
      padding: 11px !important;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.wb-dialog .wb-dialog-body {
  // padding: 10px 10px 0 0;
  padding-left: 10px !important;
}
/* 页面宽度小于768px
------------------------------- */
@media screen and (max-width: 768px) {
  .reservation-service {
    max-width: none;
    margin: 0;
    margin-top: 0 !important;
    height: auto;
    overflow: auto;
  }
  .wb-dialog .wb-dialog-body {
    padding: 10px 10px 0 0;
    .el-input {
      width: 90%;
    }
    .verification-code {
      width: calc(90% - 110px) !important;
    }
  }
}
</style>