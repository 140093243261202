import request from '@/utils/request'
import request_login from '@/utils/request_login'

/**
 * 登录
 * @param {*} data 
 * @returns 
 */
export function login(data) {
  return request_login({
    url: '/portal/ajaxLogin',
    method: 'POST',
    data: data
  })
}
/**
 * 登录sk
 * @param {*} data 
 * @returns 
 */
export function getLoginSK() {
  return request({
    url: '/api/portal/getLoginSK?_t' + new Date().getTime(),
    method: 'GET',
  })
}

/**
 * 注册前实时输入邮箱校验
 * @param {*} data 
 * @returns 
 */
export function checkNewEmail(data) {
  return request({
    url: '/account/register/newCheckEmail',
    method: 'POST',
    data: data
  })
}

/**
 * 注册
 * @param {*} data 
 * @returns 
 */
export function register(data) {
  return request({
    url: '/account/register/send',
    method: 'POST',
    data: data
  })
}
/**
 * 个人注册实时校验
 * @param {*} data 
 * @returns 
 */
export function findByCode(data) {
  return request({
    url: '/account/register/findByCode/' + data.code,
    method: 'GET',
  })
}
/**
 * 忘记密码
 * @param {*} data 
 * @returns 
 */
export function forgetPassword(data) {
  return request({
    url: '/portal/sendEmail',
    method: 'POST',
    data: data
  })
}
/**
 * 登录校验验证码
 * @param {*} data 
 * @returns 
 */
export function checkCode(data) {
  return request_login({
    url: '/portal/checkCode',
    method: 'POST',
    data: data
  })
}

/**
 * 是否显示注册按钮
 * @param {*} data 
 * @returns 
 */
export function showRegister() {
  return request({
    url: '/api/portal/showRegister',
    method: 'GET',
  })
}

/**
 * 发送短信
 * @param {*} data 
 * @returns 
 */
export function sendShortMsg(data) {
  return request({
    url: '/portal/getLoginSmsCode?username=' + data.username,
    method: 'GET',
  })
}

/**
 * 微信分享
 * @param {*} data 
 * @returns 
 */
export function wxShare(data) {
  return request({
    url: '/jsWechat',
    method: 'POST',
    data: data
  })
}


/**
 * 微信分享
 * @param {*} data 
 * @returns 
 */
export function isAlwaysShowCode(params) {
  return request({
    url: '/api/portal/isAlwaysShowCode',
    method: 'GET',
  })
}

